import React from "react";
import { TERipple } from "tw-elements-react";
import img1 from "../../images/WhatsApp Image 2024-08-03 at 20.08.41 (1).jpeg";
import img2 from "../../images/WhatsApp Image 2024-08-03 at 20.08.41 (2).jpeg";
import img3 from "../../images/WhatsApp Image 2024-08-03 at 20.08.41.jpeg";
import img4 from "../../images/WhatsApp Image 2024-08-03 at 20.08.42 (1).jpeg";
import img5 from "../../images/WhatsApp Image 2024-08-03 at 20.08.42.jpeg";
import img6 from "../../images/WhatsApp Image 2024-08-03 at 20.08.43 (1).jpeg";
import img7 from "../../images/WhatsApp Image 2024-08-03 at 20.08.43.jpeg";
import img8 from "../../images/WhatsApp Image 2024-08-03 at 20.08.44.jpeg";
import img9 from "../../images/WhatsApp Image 2024-08-03 at 20.08.45 (1).jpeg";
import img10 from "../../images/WhatsApp Image 2024-08-03 at 20.08.45.jpeg";
import img11 from "../../images/WhatsApp Image 2024-08-03 at 20.08.46 (1).jpeg";
import img12 from "../../images/WhatsApp Image 2024-08-03 at 20.08.46.jpeg";

const Qurbani = () => {
  return (
    <div className=" rounded-lg xl:px-28 mt-28  bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
      <h3 className="mb-2 text-center text-2xl font-medium leading-tight text-neutral-800 dark:text-neutral-50"></h3>

      <div className="p-6">
        <p className="my-7 font-bold text-xl  text-black">
          💧SRI LANKA DEEP WATER WELL💧
        </p>

        <p className="mb-4">
          {" "}
          Thanks 🎉to the dedication and hard work of our charity Founder &
          President Mr. Mohamed Arshath , we have been able to provide clean
          drinking water to yet another community in need.
          <br />
          Water is life, in Sri Lanka🇱🇰 some villagers, men, women and children
          do not have access to drinking water, you can help them!
        </p>
        <a href="#!">
          <img className="rounded-t-lg py-1" src={img1} alt="" />
        </a>
        <a href="#!">
          <img className="rounded-t-lg py-1" src={img2} alt="" />
        </a>
        <a href="#!">
          <img className="rounded-t-lg py-1" src={img3} alt="" />
        </a>
        <a href="#!">
          <img className="rounded-t-lg py-1" src={img4} alt="" />
        </a>

        <p className="my-8">
          Alhamdulilah few of our deep water well completed in Sri Lanka. May
          Allah accept it from everyone. Ameen. 💚
          <br />
          Multiple projects have been completed in Sri Lanka. Water is life!
          Offer a well 💧More than 150+ wells together
        </p>
        <a href="#!">
          <img className="rounded-t-lg py-1" src={img5} alt="" />
        </a>
        <a href="#!">
          <img className="rounded-t-lg py-1" src={img6} alt="" />
        </a>
        <a href="#!">
          <img className="rounded-t-lg py-1" src={img7} alt="" />
        </a>
        <a href="#!">
          <img className="rounded-t-lg py-1" src={img8} alt="" />
        </a>
        <br />
        <p>
          Total Cost of water tube well with tank used in community is £475 |
          €555 | $590. More than 10 families benefit from this type of well. Why
          not donate a deep water well on behalf of your loved ones.
        </p>

        <a href="#!">
          <img className="rounded-t-lg py-1" src={img9} alt="" />
        </a>
        <a href="#!">
          <img className="rounded-t-lg py-1" src={img10} alt="" />
        </a>
        <a href="#!">
          <img className="rounded-t-lg py-1" src={img11} alt="" />
        </a>
        <a href="#!">
          <img className="rounded-t-lg py-1" src={img12} alt="" />
        </a>
        <p>
          For more details please contact us on
          <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+94 76 731 3385
          <br />
          📩 hhmasrilanka@gmail.com
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;hhmasrilanka@gmail.com
          <br />
          ✅ 100% Donation Policy
          <br />
          📲 <a href="/">hhmasrilanka.org</a>
        </p>
      </div>
    </div>
  );
};
export default Qurbani;
